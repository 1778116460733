import { zEmailRequired, zStringRequired } from '@calories/shared/src/zod'
import { z } from 'zod'
import { zAvailableRoles } from '../../../utils/can'

export const zCreateAdminTrpcInput = z.object({
  name: zStringRequired,
  email: zEmailRequired,
  password: zStringRequired,
  role: zAvailableRoles,
})
