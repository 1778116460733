import { zStringOptional } from '@calories/shared/src/zod'
import { z } from 'zod'

export const zGetUserTopicsTrpcInput = z.object({
  cursor: z.coerce.number().optional(),
  limit: z.number().min(1).max(100).default(10),
  search: z.string().optional().nullable(),
  userId: zStringOptional,
  topicId: zStringOptional,
})
