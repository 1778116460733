import { zStringDateFromOptonal, zStringDateToOptonal, zStringOptional } from '@calories/shared/src/zod'
import { z } from 'zod'

export const zGetPostbackLogsListTrpcInput = z.object({
  // cursor: z.coerce.date().optional(),
  cursor: z.number().int().optional().nullable(),
  limit: z.number().min(1).max(100).default(10),
  urlLike: zStringOptional,
  eventNameLike: zStringOptional,
  target: z.enum(['facebook', 'google', 'custom', 'all']),
  dateFrom: zStringDateFromOptonal,
  dateTo: zStringDateToOptonal,
})
