import { zStringRequired } from '@calories/shared/src/zod'
import { z } from 'zod'

const zNewsPostGeneralInput = z.object({
  publishedAt: z.coerce.date(),
  title: zStringRequired,
  bodyMd: zStringRequired,
  imageUrl: zStringRequired,
})

const zNewsPostWithoutTopic = zNewsPostGeneralInput.extend({})

const zNewsPostWithTopic = zNewsPostGeneralInput.extend({})

export const zNewsPostInput = z.union([zNewsPostWithoutTopic, zNewsPostWithTopic])
