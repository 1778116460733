import { zCreateAdminTrpcInput } from '@calories/admin-backend/src/router/admins/createAdmin/input'
import { availableRolesOptions, canManageAdmins } from '@calories/admin-backend/src/utils/can'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RadioButtons } from '../../../components/RadioButtons'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getAdminsRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const AdminNewPage = withPageWrapper({
  checkAccess: ({ ctx }) => canManageAdmins(ctx.me),
  title: 'New Admin',
})(() => {
  const navigate = useNavigate()
  const createAdmin = trpc.createAdmin.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      email: '',
      name: '',
      role: 'analyst',
      password: '',
    },
    validationSchema: zCreateAdminTrpcInput,
    onSubmit: async (values) => {
      await createAdmin.mutateAsync(values)
      navigate(getAdminsRoute())
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Admin created successfully',
  })
  return (
    <Segment title="New Admin">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Name" name="name" formik={formik} />
          <Input label="Email" name="email" formik={formik} />
          <Input label="Password" name="password" formik={formik} />
          <RadioButtons options={availableRolesOptions} label="Role" name="role" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create Admin</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
