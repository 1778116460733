import type { TrpcRouterOutput } from '@calories/admin-backend/src/router'
import { zGetUserAnalyticTrpcInput } from '@calories/admin-backend/src/router/users/getUserAnalytic/input'
import { zUpdateAppliedAddCoinsEventsTrpcInput } from '@calories/admin-backend/src/router/users/updateAppliedAddCoinsEvents/input'
import { zUpdateUserCoinsTrpcInput } from '@calories/admin-backend/src/router/users/updateUserCoins/input'
import { canViewUsers } from '@calories/admin-backend/src/utils/can'
import { addDays } from 'date-fns'
import { Link } from 'react-router-dom'
import { z } from 'zod'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
// import { env } from '../../../lib/env'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getUserTopicsRoute, getUserImagesRoute, getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const Destroy = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const destroyUser = trpc.destroyUser.useMutation()
  // const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      destroy: '' as any,
    },
    validationSchema: z.object({
      destroy: z.string(),
    }),
    onSubmit: async (values) => {
      if (values.destroy.toLowerCase().trim() !== user.serialNumber.toString()) {
        throw new Error('Confirmation is not correct')
      }
      await destroyUser.mutateAsync({
        userId: user.id,
      })
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'User destroyed',
  })
  return (
    <Segment size={2} title="Destroy">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Confirmation" hint="Type user serial number to confirm" name="destroy" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps} disabled={buttonProps.disabled}>
            Destroy
          </Button>
        </FormItems>
      </form>
    </Segment>
  )
}

const Subscription = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const updateUserSubscription = trpc.updateUserSubscription.useMutation()
  // const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      expiresAfterDays: '' as any,
    },
    validationSchema: z.object({
      expiresAfterDays: z.string().regex(/^\d+$/),
    }),
    onSubmit: async (values) => {
      await updateUserSubscription.mutateAsync({
        serialNumber: user.serialNumber,
        expiresAt: addDays(new Date(), +values.expiresAfterDays),
      })
      // trpcUtils.getAppConfig.setData(undefined, result)
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Subscription expiration date updated. Will be applied in next 2 minutes.',
  })
  return (
    <Segment size={2} title="Subscription">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Expires After Days Count" name="expiresAfterDays" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Update</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

const Coins = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const updateUserCoins = trpc.updateUserCoins.useMutation()
  const trpcUtils = trpc.useUtils()
  const { formik, alertProps, buttonProps } = useForm({
    validationSchema: zUpdateUserCoinsTrpcInput.omit({
      serialNumber: true,
    }),
    initialValues: {
      freeCoinsCountPerMonth: user.freeCoinsCountPerMonth.toString() as any,
      coinsCount: user.coinsCount.toString() as any,
    },
    onSubmit: async (values) => {
      const result = await updateUserCoins.mutateAsync({
        serialNumber: user.serialNumber,
        ...values,
      })
      trpcUtils.getUser.setData(
        {
          serialNumber: user.serialNumber,
        },
        (prevData) =>
          prevData && {
            ...prevData,
            user: {
              ...prevData.user,
              ...result.user,
            },
          }
      )
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Coins updated',
  })
  return (
    <Segment size={2} title="Coins">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Free Coins Count Per Month" name="freeCoinsCountPerMonth" formik={formik} />
          <Input label="Current Coins Count" name="coinsCount" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Update</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

const AppliedAddCoinsEvents = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const updateAppliedAddCoinsEvents = trpc.updateAppliedAddCoinsEvents.useMutation()
  const trpcUtils = trpc.useUtils()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      appliedAddCoinsEvents: user.appliedAddCoinsEvents.join('\n'),
    },
    validationSchema: z.object({
      appliedAddCoinsEvents: z.string(),
    }),
    onSubmit: async (values) => {
      const appliedAddCoinsEvents = values.appliedAddCoinsEvents
        .split('\n')
        .map((x) => x.trim())
        .filter((x) => x)

      const input = zUpdateAppliedAddCoinsEventsTrpcInput.parse({
        appliedAddCoinsEvents,
        serialNumber: user.serialNumber,
      })
      const result = await updateAppliedAddCoinsEvents.mutateAsync(input)
      trpcUtils.getUser.setData(
        {
          serialNumber: user.serialNumber,
        },
        (prevData) =>
          prevData && {
            ...prevData,
            user: {
              ...prevData.user,
              ...result.user,
            },
          }
      )
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Applied add coins events updated',
  })

  return (
    <Segment title="Applied Coins Events" size={2}>
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Textarea label="Event Names" name="appliedAddCoinsEvents" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Update</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

const ananlyticValidationSchema = zGetUserAnalyticTrpcInput
const Ananlytic = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const { formik } = useForm({
    initialValues: { dateFrom: '', dateTo: '', userId: user.id },
    validationSchema: ananlyticValidationSchema,
  })
  const filter: any = (() => {
    const maybeFilter = {
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
      userId: user.id,
    }
    const parseResult = ananlyticValidationSchema.safeParse(maybeFilter)
    return parseResult.success
      ? maybeFilter
      : {
          dateFrom: '',
          dateTo: '',
          userId: user.id,
        }
  })()
  const queryResult = trpc.getUserAnalytic.useQuery(filter)
  return (
    <Segment title="Ananlytic" size={2}>
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={formik} />
        </FormItems>
      </div>
      {queryResult.error ? (
        <Alert color="red">{queryResult.error.message}</Alert>
      ) : !queryResult.data ? (
        <Loader type="section" />
      ) : (
        <div className={css.result}>
          <pre className={css.data}>{JSON.stringify(queryResult.data, null, 2)}</pre>
        </div>
      )}
    </Segment>
  )
}

export const UserPage = withPageWrapper({
  useQuery: () => {
    const { userSerialNumber } = getUserRoute.useParams()
    return trpc.getUser.useQuery({
      serialNumber: +userSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    user: queryResult.data.user,
  }),
  checkAccess: ({ ctx }) => canViewUsers(ctx.me),
  showLoaderOnFetching: false,
  title: ({ user }) => `User ${user.serialNumber}`,
})(({ user }) => (
  <Segment title={`User ${user.serialNumber}`}>
    <Segment>
      <div className={css.extra}>
        <a href={`https://app.adapty.io/profiles/users?filter%5Bsearch%5D=${user.id}`} target="_blank" rel="noreferrer">
          Adapty Profile
        </a>
        <br />
        <Link
          to={getUserImagesRoute({
            userSerialNumber: user.serialNumber.toString(),
          })}
        >
          Generated Images
        </Link>
        <br />
        <Link
          to={getUserTopicsRoute({
            userSerialNumber: user.serialNumber.toString(),
          })}
        >
          Topics List
        </Link>
      </div>
      <pre className={css.data}>{JSON.stringify(user, null, 2)}</pre>
    </Segment>
    <Ananlytic user={user} />
    <Subscription user={user} />
    <Coins user={user} />
    <AppliedAddCoinsEvents user={user} />
    {/* {env.HOST_ENV !== 'production' && <Destroy user={user} />} */}
    <Destroy user={user} />
  </Segment>
))
