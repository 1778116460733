import { zStringDateFromOptonal, zStringDateToOptonal, zStringOptional } from '@calories/shared/src/zod'
import { z } from 'zod'

export const zGetRetentionTrpcInput = z.object({
  search: zStringOptional,
  groupBy: zStringOptional,
  purchaseX: z.coerce.number().default(1),
  purchaseY: z.coerce.number().default(5),
  dateFrom: zStringDateFromOptonal,
  dateTo: zStringDateToOptonal,
})
