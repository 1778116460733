import { canViewUnsubscriptions } from '@calories/admin-backend/src/utils/can'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getUnsubscriptionRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const UnsubscriptionPage = withPageWrapper({
  useQuery: () => {
    const { unsubscriptionSerialNumber } = getUnsubscriptionRoute.useParams()
    return trpc.getUnsubscription.useQuery({
      serialNumber: +unsubscriptionSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    unsubscription: queryResult.data.unsubscription,
  }),
  checkAccess: ({ ctx }) => canViewUnsubscriptions(ctx.me),
  showLoaderOnFetching: false,
  title: ({ unsubscription }) => `Unsubscription: ${unsubscription.serialNumber}`,
})(({ unsubscription }) => (
  <Segment title={`Unsubscription: ${unsubscription.serialNumber}`}>
    <Segment>
      <pre className={css.unsubscriptionInfo}>{JSON.stringify(unsubscription, null, 2)}</pre>
    </Segment>
  </Segment>
))
