import { canManageTickets } from '@calories/admin-backend/src/utils/can'
import { format } from 'date-fns'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getTicketRoute, getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const TicketsPage = withPageWrapper({
  title: 'Tickets',
  checkAccess: ({ ctx }) => canManageTickets(ctx.me),
})(() => {
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getTickets.useInfiniteQuery(
      {},
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="Tickets">
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].tickets.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <div className={css.tickets}>
          <table className={css.table}>
            <thead>
              <tr>
                <th>Ticket SN</th>
                <th>User SN</th>
                <th>Title</th>
                <th>Update At</th>
                <th>Last Comment Author</th>
                <th>Last Comment Text</th>
                <th>Status</th>
              </tr>
            </thead>
            <InfiniteScroll
              threshold={250}
              loadMore={() => {
                if (!isFetchingNextPage && hasNextPage) {
                  void fetchNextPage()
                }
              }}
              className={css.tbody}
              hasMore={hasNextPage}
              loader={
                <tr>
                  <td className={css.more} key="loader">
                    <Loader type="section" />
                  </td>
                </tr>
              }
              getScrollParent={() => layoutContentElRef.current}
              useWindow={
                (layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'
              }
            >
              {data.pages
                .flatMap((page) => page.tickets)
                .map((ticket) => (
                  <tr className={css.ticket} key={ticket.serialNumber}>
                    <td>
                      <Link
                        className={css.ticketLink}
                        to={getTicketRoute({
                          ticketSerialNumber: ticket.serialNumber.toString(),
                        })}
                      >
                        {ticket.serialNumber}
                      </Link>
                    </td>
                    <td>
                      <Link
                        className={css.ticketLink}
                        to={getUserRoute({
                          userSerialNumber: ticket.user.serialNumber.toString(),
                        })}
                      >
                        {ticket.user.serialNumber}
                      </Link>
                    </td>
                    <td>
                      {!ticket.title
                        ? '—'
                        : ticket.title.length > 50
                        ? ticket.title.slice(0, 50) + '...'
                        : ticket.title}
                    </td>
                    <td>{format(new Date(ticket.updatedAt), 'yyyy-MM-dd HH:mm')}</td>
                    <td>{ticket.lastComment.authorRole}</td>
                    <td>
                      {ticket.lastComment.text.length > 50
                        ? ticket.lastComment.text.slice(0, 50) + '...'
                        : ticket.lastComment.text}
                    </td>
                    <td>{ticket.closedAt ? 'Closed' : 'Active'}</td>
                  </tr>
                ))}
            </InfiniteScroll>
          </table>
        </div>
      )}
    </Segment>
  )
})
