import { zUpdateDocTrpcInput } from '@calories/admin-backend/src/router/docs/updateDoc/input'
import { canManageDocs } from '@calories/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { Button, Buttons, LinkButton } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RichEditor } from '../../../components/RichEditor'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditDocRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const DocEditPage = withPageWrapper({
  useQuery: () => {
    const { docId } = getEditDocRoute.useParams()
    return trpc.getDoc.useQuery({
      id: docId,
    })
  },
  setProps: ({ queryResult }) => ({
    doc: queryResult.data.doc,
  }),
  checkAccess: ({ ctx }) => canManageDocs(ctx.me),
  showLoaderOnFetching: false,
  title: ({ doc }) => `Doc: ${doc.title}`,
})(({ doc }) => {
  const updateDoc = trpc.updateDoc.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      // title: doc.title,
      // body: doc.body,
      // buildNumber: doc.buildNumber.toString() as any,
      // imageUrl: doc.imageUrl,
      publishedAt: doc.publishedAt,
      title: doc.title,
      bodyMd: doc.bodyMd,
      urlSuffix: doc.urlSuffix,
    },
    validationSchema: zUpdateDocTrpcInput.shape.data,
    onSubmit: async (values) => {
      await updateDoc.mutateAsync({
        id: doc.id,
        data: values,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Doc updated successfully',
  })

  const deleteDoc = trpc.deleteDoc.useMutation()
  const deleteForm = useForm({
    onSubmit: async () => {
      await deleteDoc.mutateAsync({
        id: doc.id,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Doc deleted successfully',
  })
  return (
    <Segment title={`Doc: ${doc.title}`} description={doc.id}>
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Published At" name="publishedAt" type="datetime-local" formik={formik} />
          <Input label="Title" name="title" formik={formik} />
          <Input label="Url Suffix" name="urlSuffix" formik={formik} />
          <RichEditor label="Body" name="bodyMd" formik={formik} />
          <Alert {...alertProps} />
          <Alert {...deleteForm.alertProps} />
          <Buttons>
            <Button {...buttonProps}>Update Doc</Button>
            <LinkButton href={doc.url} target="_blank">
              Visit
            </LinkButton>
            <Button
              {...deleteForm.buttonProps}
              color="red"
              type="button"
              onClick={() => {
                deleteForm.formik.handleSubmit()
              }}
            >
              Delete
            </Button>
          </Buttons>
        </FormItems>
      </form>
    </Segment>
  )
})
