import { zUpdateUpgradePostTrpcInput } from '@calories/admin-backend/src/router/upgradesPosts/updateUpgradePost/input'
import { canManageUpgradesPosts } from '@calories/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { Button, Buttons } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { UploadToS3 } from '../../../components/UploadToS3'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditUpgradePostRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const UpgradePostEditPage = withPageWrapper({
  useQuery: () => {
    const { upgradePostId } = getEditUpgradePostRoute.useParams()
    return trpc.getUpgradePost.useQuery({
      id: upgradePostId,
    })
  },
  setProps: ({ queryResult }) => ({
    upgradePost: queryResult.data.upgradePost,
  }),
  checkAccess: ({ ctx }) => canManageUpgradesPosts(ctx.me),
  showLoaderOnFetching: false,
  title: ({ upgradePost }) => `Upgrade Post: ${upgradePost.title}`,
})(({ upgradePost }) => {
  const updateUpgradePost = trpc.updateUpgradePost.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      id: upgradePost.id,
      title: upgradePost.title,
      body: upgradePost.body,
      buildNumber: upgradePost.buildNumber.toString() as any,
      imageUrl: upgradePost.imageUrl,
    },
    validationSchema: zUpdateUpgradePostTrpcInput,
    onSubmit: async (values) => {
      await updateUpgradePost.mutateAsync(values)
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Upgrade Post updated successfully',
  })

  const deleteUpgradePost = trpc.deleteUpgradePost.useMutation()
  const deleteForm = useForm({
    onSubmit: async () => {
      await deleteUpgradePost.mutateAsync({
        id: upgradePost.id,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Upgrade Post deleted successfully',
  })
  return (
    <Segment title={`Upgrade Post: ${upgradePost.title}`}>
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Build Number" name="buildNumber" formik={formik} />
          <Input label="Title" name="title" formik={formik} />
          <Textarea label="Body" name="body" formik={formik} />
          <UploadToS3 label="Image" name="imageUrl" formik={formik} accept="image/*" />
          <Alert {...alertProps} />
          <Alert {...deleteForm.alertProps} />
          <Buttons>
            <Button {...buttonProps}>Update Upgrade Post</Button>
            <Button
              {...deleteForm.buttonProps}
              color="red"
              type="button"
              onClick={() => {
                deleteForm.formik.handleSubmit()
              }}
            >
              Delete
            </Button>
          </Buttons>
        </FormItems>
      </form>
    </Segment>
  )
})
