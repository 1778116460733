import { z } from 'zod'

// TODO:NEW fix auto campaigns

type AutoCampaignsConfig = Record<
  | 'reminder'
  | 'inactive'
  | 'cancelAutorenewal'
  | 'spentAllCoins'
  | 'noTopic'
  | 'assistantMisses'
  | 'onboardingSkipped'
  | 'notSubscribed',
  {
    messages: Array<{ title: string; body: string; path: string }>
  }
>

const autoCampaignsTypes = [
  'reminder',
  'inactive',
  'cancelAutorenewal',
  'spentAllCoins',
  'noTopic',
  'assistantMisses',
  'onboardingSkipped',
  'notSubscribed',
] as const
const zAutoCampaignsType = z.enum(autoCampaignsTypes)
const zAutoCampaignsConfig = z.record(
  zAutoCampaignsType,
  z.object({
    messages: z.array(
      z.object({
        title: z.string(),
        body: z.string(),
        path: z.string(),
      })
    ),
  })
)

export const autoCampaignsConfigFromTextToObject = (text: string): AutoCampaignsConfig => {
  try {
    const obj = JSON.parse(text)
    const result = zAutoCampaignsConfig.parse(obj)
    const keys = Object.keys(result)
    const undefinedKeys = autoCampaignsTypes.filter((type) => !keys.includes(type))
    if (undefinedKeys.length > 0) {
      throw new Error(`Undefined keys: ${undefinedKeys.join(', ')}`)
    }
    return result as AutoCampaignsConfig
  } catch (error) {
    throw new Error('Invalid JSON')
  }
}

export const autoCampaignsConfigFromObjectToText = (obj: AutoCampaignsConfig): string => {
  return JSON.stringify(obj, null, 2)
}

export const zAutoCampaignsConfigInput = z
  .string()
  .refine((text) => {
    try {
      autoCampaignsConfigFromTextToObject(text)
      return true
    } catch (error) {
      return false
    }
  })
  .transform((text) => autoCampaignsConfigFromTextToObject(text))
