import { zGetUsersTrpcInput } from '@calories/admin-backend/src/router/users/getUsers/input'
import { canViewUsers } from '@calories/admin-backend/src/utils/can'
import { usersGroupsOsLabelsSelectOptions } from '@calories/shared/src/usersGroups'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { RadioButtons } from '../../../components/RadioButtons'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetUsersTrpcInput.pick({
  serialNumber: true,
  email: true,
  usersGroupOs: true,
  subscriptionStatus: true,
  subscriptionProvider: true,
  createdAtFrom: true,
  createdAtTo: true,
  subscribedAtFrom: true,
  subscribedAtTo: true,
})
const initialValues = {
  serialNumber: '',
  email: '',
  usersGroupOs: 'allOs' as const,
  subscriptionStatus: 'any' as const,
  subscriptionProvider: 'any' as const,
  createdAtFrom: '',
  createdAtTo: '',
  subscribedAtFrom: '',
  subscribedAtTo: '',
}
export const UsersPage = withPageWrapper({
  title: 'Users',
  checkAccess: ({ ctx }) => canViewUsers(ctx.me),
})(() => {
  const searchForm = useForm({
    initialValues,
    validationSchema,
  })
  const getUsersCsv = trpc.getUsersCsv.useMutation()
  const csvForm = useForm({
    onSubmit: async () => {
      const { base64 } = await getUsersCsv.mutateAsync({
        serialNumber: searchForm.formik.values.serialNumber,
        email: searchForm.formik.values.email,
        usersGroupOs: searchForm.formik.values.usersGroupOs,
        subscriptionStatus: searchForm.formik.values.subscriptionStatus,
        subscriptionProvider: searchForm.formik.values.subscriptionProvider,
        createdAtFrom: searchForm.formik.values.createdAtFrom?.toString(),
        createdAtTo: searchForm.formik.values.createdAtTo?.toString(),
        subscribedAtFrom: searchForm.formik.values.subscribedAtFrom?.toString(),
        subscribedAtTo: searchForm.formik.values.subscribedAtTo?.toString(),
      })
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'text/csv' })
      saveAs(blob, 'users.csv')
    },
  })
  const filter = (() => {
    const maybeFilter = {
      serialNumber: searchForm.formik.values.serialNumber,
      email: searchForm.formik.values.email,
      usersGroupOs: searchForm.formik.values.usersGroupOs,
      subscriptionStatus: searchForm.formik.values.subscriptionStatus,
      subscriptionProvider: searchForm.formik.values.subscriptionProvider,
      createdAtFrom: searchForm.formik.values.createdAtFrom,
      createdAtTo: searchForm.formik.values.createdAtTo,
      subscribedAtFrom: searchForm.formik.values.subscribedAtFrom,
      subscribedAtTo: searchForm.formik.values.subscribedAtTo,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return (parseResult.success ? maybeFilter : initialValues) as typeof initialValues
  })()
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getUsers.useInfiniteQuery(
      {
        ...filter,
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="Users">
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Serial Number" name="serialNumber" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Email" name="email" formik={searchForm.formik} />

          <RadioButtons
            label="User OS"
            name="usersGroupOs"
            options={usersGroupsOsLabelsSelectOptions}
            formik={searchForm.formik}
          />
          <RadioButtons
            label="Subscription Status"
            name="subscriptionStatus"
            options={[
              { label: 'Any', value: 'any' },
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
              { label: 'Never', value: 'never' },
            ]}
            formik={searchForm.formik}
          />
          <RadioButtons
            label="Subscription Provider"
            name="subscriptionProvider"
            options={[
              { label: 'Any', value: 'any' },
              { label: 'Stripe', value: 'stripe' },
              { label: 'Adapty', value: 'adapty' },
            ]}
            formik={searchForm.formik}
          />
          <Input maxWidth={'100%'} label="Created At From" name="createdAtFrom" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Created At To" name="createdAtTo" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Subscribed At From" name="subscribedAtFrom" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Subscribed At To" name="subscribedAtTo" formik={searchForm.formik} />
          {!error && (
            <Button
              type="button"
              onClick={() => {
                csvForm.formik.handleSubmit()
              }}
            >
              Download CSV
            </Button>
          )}
        </FormItems>
      </div>
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].users.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <div className={css.users}>
          <table className={css.table}>
            <thead>
              <tr>
                <th>SN</th>
                <th>Created At</th>
                <th>E-mail</th>
                <th>Subscription Provider</th>
                <th>Subscribed At</th>
                <th>Subscription Expires At</th>
              </tr>
            </thead>
            <InfiniteScroll
              threshold={250}
              loadMore={() => {
                if (!isFetchingNextPage && hasNextPage) {
                  void fetchNextPage()
                }
              }}
              className={css.tbody}
              hasMore={hasNextPage}
              loader={
                <tr key={'loader'}>
                  <td className={css.more} key="loader">
                    <Loader type="section" />
                  </td>
                </tr>
              }
              element={'tbody'}
              getScrollParent={() => layoutContentElRef.current}
              useWindow={
                (layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'
              }
            >
              {data.pages
                .flatMap((page) => page.users)
                .map((user) => (
                  <tr className={css.user} key={user.serialNumber}>
                    <td>
                      <Link
                        className={css.userLink}
                        to={getUserRoute({ userSerialNumber: user.serialNumber.toString() })}
                      >
                        {user.serialNumber}
                      </Link>
                    </td>
                    <td>{format(user.createdAt, 'dd.MM.yyyy HH:mm:ss')}</td>
                    <td>{user.email}</td>
                    <td>{user.subscriptionProvider}</td>
                    <td>{user.subscribedAt ? format(user.subscribedAt, 'dd.MM.yyyy HH:mm:ss') : '—'}</td>
                    <td>
                      {user.subscriptionExpiresAt ? format(user.subscriptionExpiresAt, 'dd.MM.yyyy HH:mm:ss') : '—'}
                    </td>
                  </tr>
                ))}
            </InfiniteScroll>
          </table>
        </div>
      )}
    </Segment>
  )
})
